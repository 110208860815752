import React from 'react';
import styled from 'styled-components';

import {
  cancelBookingRenewal,
  getMerchantRentalConfiguration,
  getRentalBooking,
  getRentalBookingFiles,
  getRentalClaimByWaiverId
} from '@oysterjs/core/api/merchant';
import { Button, ButtonContainer, ButtonLink } from '../../components/Button';
import { Spinner } from '@oysterjs/ui/Spinner';
import {
  AttachmentFile,
  DefaultFileRoles,
  Insured,
  MerchantRentalConfiguration,
  RentalBooking,
  RentalClaim,
  RentalClaimState,
  RentalWaiver,
  WaiverState
} from '@oysterjs/types';
import { DetailsContainer, RentalPageContainer } from './components';
import { Badge } from '@oysterjs/ui/Badge';
import { IoBicycle, IoPerson, IoRefresh } from 'react-icons/io5';
import { Table } from '@oysterjs/ui/Table';
import { getRentalDocumentUrl } from '@oysterjs/core/statics';

const InsuredItemContainer = styled.div`
  border-radius: 16px;
  padding: 16px;
  border: 2px dotted #eaeaea;
  transition: all 0.15s ease-in-out;
  display: flex;
  gap: 16px;
  flex: 1 0 0;
`;

const InsuredItemDetails = styled.div`
  flex: 1 0 0px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const InsuredItemTitle = styled.div`
  gap: 16px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
`;

const InsuredItemContent = styled.div`
  font-size: 0.9em;
  color: #666666;
`;

export const RentalDescription = (props: {
  itemName: string;
  serialNumber: string;
  accessories: string[];
  startDate: Date;
  endDate: Date;
}) => {
  const formattedRentalDate = () => {
    const f = new Intl.DateTimeFormat(undefined, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit'
    });

    return `${f.format(props.startDate)} - ${f.format(props.endDate)}`;
  };

  return (
    <InsuredItemContainer>
      <div>
        <IoBicycle style={{ fontSize: '2.5em', color: '#637D8C' }} />
      </div>
      <InsuredItemDetails>
        <InsuredItemTitle>
          <div>{props.itemName}</div>
        </InsuredItemTitle>
        <InsuredItemContent>
          <div>{props.serialNumber}</div>
          {props.accessories.length > 0 && <div>{props.accessories.join(', ')}</div>}
          <div>{formattedRentalDate()}</div>
        </InsuredItemContent>
      </InsuredItemDetails>
    </InsuredItemContainer>
  );
};

export const InsuredDescription = (props: { insured: Insured }) => (
  <InsuredItemContainer>
    <div>
      <IoPerson style={{ fontSize: '2.5em', color: '#637D8C' }} />
    </div>
    <InsuredItemDetails>
      <InsuredItemTitle>
        <div>
          {props.insured.FirstName} {props.insured.LastName}
        </div>
      </InsuredItemTitle>
      <InsuredItemContent>
        <div>{props.insured.Email}</div>
        {props.insured.AddressCity &&
          props.insured.AddressState &&
          props.insured.AddressZipCode && (
            <div>
              {props.insured.AddressCity.trim()}, {props.insured.AddressState.trim()}{' '}
              {props.insured.AddressZipCode.trim()}
            </div>
          )}
        {!props.insured.AddressCity && props.insured.Phone && <div>{props.insured.Phone}</div>}
      </InsuredItemContent>
    </InsuredItemDetails>
  </InsuredItemContainer>
);

export const RenewalDescription = (props: {
  waiver?: RentalWaiver;
  booking: RentalBooking;
  showRenewalPeriod?: boolean;
}) => {
  const f = new Intl.DateTimeFormat(undefined, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit'
  });

  return (
    <InsuredItemContainer>
      <div>
        <IoRefresh style={{ fontSize: '2.5em', color: '#637D8C' }} />
      </div>
      <InsuredItemDetails>
        <InsuredItemTitle>
          <div>{props.waiver ? 'Booking and Waiver Renewal' : 'Booking Renewal'}</div>
        </InsuredItemTitle>
        <InsuredItemContent>
          {props.booking.Details.AutoRenew && !props.showRenewalPeriod && (
            <>Will renew on {`${f.format(new Date(props.booking.EndTime))}`}</>
          )}
          {props.booking.Details.AutoRenew && props.showRenewalPeriod && (
            <>
              Will renew every{' '}
              {`${
                (new Date(props.booking.EndTime).valueOf() -
                  new Date(props.booking.StartTime).valueOf()) /
                86400000
              }`}{' '}
              days
            </>
          )}
          {!props.booking.Details.AutoRenew && <>Renewal canceled</>}
        </InsuredItemContent>
      </InsuredItemDetails>
    </InsuredItemContainer>
  );
};

export const RentalBookingDetailsPage = (props: {
  bookingId: string;
  onCreateWaiver: (booking: RentalBooking) => void;
  onViewWaiver: (waiver: RentalWaiver) => void;
  onFileClaim: (waiver: RentalWaiver) => void;
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [rentalConfig, setRentalConfig] = React.useState<MerchantRentalConfiguration>();
  const [waiver, setWaiver] = React.useState<RentalWaiver>();
  const [booking, setBooking] = React.useState<RentalBooking>();
  const [claim, setClaim] = React.useState<RentalClaim>();
  const [files, setFiles] = React.useState<AttachmentFile[] | null>(null);

  const onFetchData = async () => {
    if (!props.bookingId) {
      return;
    }

    // If we're refreshing, avoid loading flash by suppressing the loader
    if (!booking) {
      setLoading(true);
    }

    const entry = await getRentalBooking(props.bookingId);
    if (!entry.Booking) {
      throw new Error(`Booking ${props.bookingId} does not exist`);
    }
    setBooking(entry.Booking);
    setWaiver(entry.Waiver);

    const config = await getMerchantRentalConfiguration();
    setRentalConfig(config.Configuration);

    // Get claim if waiver exists
    if (entry.Waiver) {
      const res = await getRentalClaimByWaiverId(entry.Waiver?.ID);
      setClaim(res.Claim);
    }

    // Get documents/files if booking exists
    if (entry.Booking) {
      const res = await getRentalBookingFiles(entry.Booking?.ID);
      setFiles(res);
    }

    setLoading(false);
  };

  const onCancelRenewal = async () => {
    setLoading(true);
    const updatedBooking = await cancelBookingRenewal(props.bookingId);
    if (updatedBooking) {
      setBooking(updatedBooking);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    onFetchData();
  }, [props.bookingId]);

  React.useEffect(() => {
    window.addEventListener('focus', onFetchData);
    return () => window.removeEventListener('focus', onFetchData);
  });

  const getCompactRentedAssets = (b: RentalBooking): string => {
    if (!b.Details.Assets?.length) {
      return '';
    }

    return (
      b.Details.Assets[0].Name +
      (b.Details.Assets.length > 1 ? ` and ${b.Details.Assets.length - 1} other(s)` : '')
    );
  };

  const getWaiverStatus = (b?: RentalBooking, w?: RentalWaiver) => {
    if (!b) {
      return <Badge color="#e6e6e6" label="Loading" />;
    }

    if (!w || w.State == WaiverState.unknown) {
      return <Badge color="#e6e6e6" label="No Waiver" />;
    }

    switch (w.State) {
      case WaiverState.pending:
        return <Badge color="#ffa72494" label="Waiver Pending" />;
      case WaiverState.canceled:
        return <Badge color="#e6e6e6" label="Waiver Canceled" />;
      case WaiverState.active:
      case WaiverState.expired:
        return <Badge color={'#baf7bb'} label="Waiver Active" />;
      default:
        return <Badge color="#e6e6e6" label="Unknown" />;
    }
  };

  const getClaimStatus = (c?: RentalClaim) => {
    switch (c?.State) {
      case RentalClaimState.requires_information:
        return 'Please provide all required information and submit the claim.';
      case RentalClaimState.logged:
      case RentalClaimState.submitted:
        return 'Claim has been submitted. We will process your information and notify you of any updates.';
      case RentalClaimState.approved:
        return 'Claim has been approved.';
      case RentalClaimState.denied:
        return 'Claim has been denied. Please contact us if you need any support.';
      default:
        return undefined;
    }
  };

  const getFileNameForRentalFiles = (file: AttachmentFile): string => {
    switch (file.Attachment?.Role) {
      case DefaultFileRoles.signedRentalAgreement:
        return 'Signed Rental Agreement';
      case DefaultFileRoles.waiverLDWDocument:
        return 'Loss Damage Waiver';
      default:
        return 'Other';
    }
    return '';
  };

  return (
    <RentalPageContainer
      title={booking ? `${booking.Details.Insured.FirstName}'s Booking` : 'Loading Booking...'}
      badge={getWaiverStatus(booking, waiver)}
      description={
        booking
          ? `${booking.Details.Insured.FirstName} rented ${getCompactRentedAssets(
              booking
            )} on ${new Date(booking.StartTime).toLocaleString()}`
          : "Please wait while we load this booking's details."
      }
    >
      {loading && <Spinner color="#333333" />}
      {!loading && booking && (
        <>
          <DetailsContainer>
            <InsuredDescription insured={booking.Details.Insured} />
            {rentalConfig?.Details.AutoRenewWaiver &&
              waiver &&
              waiver.State === WaiverState.active && (
                <RenewalDescription waiver={waiver} booking={booking} />
              )}
            {booking.Details.Assets?.map((asset) => (
              <RentalDescription
                key={asset.ID}
                itemName={asset.Name}
                serialNumber={asset.Details.SerialNumber}
                accessories={booking.Details.Accessories.map((a) => a.Name)}
                startDate={new Date(booking.StartTime)}
                endDate={new Date(booking.EndTime)}
              />
            ))}
            {booking.Details.BookingLineItems?.map((asset) => (
              <RentalDescription
                key={asset.SerialNumber}
                itemName={asset.Name}
                serialNumber={asset.SerialNumber}
                accessories={booking.Details.Accessories.map((a) => a.Name)}
                startDate={new Date(booking.StartTime)}
                endDate={new Date(booking.EndTime)}
              />
            ))}
          </DetailsContainer>

          <ButtonContainer style={{ padding: '20px 0px' }}>
            {booking &&
              waiver &&
              rentalConfig?.Details.AutoRenewWaiver &&
              booking.Details.AutoRenew &&
              waiver &&
              waiver.State === WaiverState.active && (
                <Button primary onClick={() => onCancelRenewal()}>
                  Cancel Auto Renew
                </Button>
              )}
            {(!waiver || waiver.State === WaiverState.unknown) && (
              <Button primary onClick={() => props.onCreateWaiver(booking)}>
                Create Waiver
              </Button>
            )}
            {waiver && waiver.State === WaiverState.pending && (
              <Button primary onClick={() => props.onViewWaiver(waiver)}>
                Edit Waiver
              </Button>
            )}
            {waiver &&
              (waiver.State === WaiverState.active || waiver.State === WaiverState.expired) && (
                <>
                  <p>{getClaimStatus(claim)}</p>
                  {(!claim ||
                    claim.State === RentalClaimState.unknown ||
                    claim.State === RentalClaimState.requires_information) && (
                    <Button onClick={() => props.onFileClaim(waiver)}>File a Claim</Button>
                  )}
                </>
              )}
          </ButtonContainer>

          {files && files.length > 0 && (
            <Table style={{ textAlign: 'left' }}>
              <thead>
                <tr>
                  <th style={{ width: '100%' }}>Booking documents and files</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {files.map((a) => (
                  <tr key={a.ID}>
                    <td>{getFileNameForRentalFiles(a)}</td>
                    <td>
                      <ButtonLink primary target="_blank" href={getRentalDocumentUrl(a.ID)}>
                        View
                      </ButtonLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </>
      )}
    </RentalPageContainer>
  );
};
