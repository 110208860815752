import * as React from 'react';
import styled from 'styled-components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { OysterLogoOld } from '@oysterjs/ui/Logo';
import { FooterStyle, PageContainer, PageSection } from '@oysterjs/ui/Page/index';
import { CoverageItem, CoveragePageSection } from '@oysterjs/ui/CoverageItem';
import {
  ReferralLinkIntegration,
  MerchantIntegrationType,
  Insured,
  PublicMerchantRentalConfiguration
} from '@oysterjs/types';
import { IoBicycle, IoPerson } from 'react-icons/io5';
import { getMerchantLink, getPublicMerchantRentalConfiguration } from '@oysterjs/core/api/merchant';
import { setMerchantAttribution } from '@oysterjs/core/analytics/attribution';
import { Spinner } from '@oysterjs/ui/Spinner';
import { TextSkeleton } from '@oysterjs/ui/Text/skeleton';
import { RentalApplication } from './rental';

const TransitionContainer = styled.div`
  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
    transition: 0.3s opacity ease;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
    transition: 0.3s opacity ease;
  }
`;

const PageBackground = styled.div<{ merchantBrandColor?: string; fitToContent?: boolean }>`
  background: linear-gradient(
    90deg,
    ${(props) => props.merchantBrandColor || '#0f172a'} 50%,
    white 50%
  );
  min-height: ${(props) => (props.fitToContent ? '0%' : '100%')};
  width: 100%;
  position: absolute;

  @media (max-width: 700px) {
    background: none;
  }
`;

const LogoContainer = styled.div<{ merchantBrandColor?: string }>`
  max-width: 50%;

  @media (max-width: 700px) {
    max-width: 100%;
    background: ${(props) => props.merchantBrandColor || '#0f172a'};
    transition: 0.15s ease-in-out background;
  }
`;

const disabledMerchants = ['gfs', 'garyfujiokasr'];

const useMerchant = (
  integrationType?: MerchantIntegrationType,
  integrationID?: string,
  externalID?: string,
  loadRentalConfiguration?: boolean
): [ReferralLinkIntegration | null, boolean, boolean, PublicMerchantRentalConfiguration | null] => {
  const [integration, setIntegration] = React.useState<ReferralLinkIntegration | null>(null);
  const [rentalConfig, setRentalConfig] = React.useState<PublicMerchantRentalConfiguration | null>(
    null
  );
  // Loading indicates that the merchant public handle is being looked up
  // and the application should wait until loading is complete to render.
  const [loading, setLoading] = React.useState(true);
  // bgLoading indicates that the merchant public handle is still being looked up
  // but the application should continue and render while the data is being loaded.
  const [bgLoading, setBGLoading] = React.useState(false);

  if (!integrationID && !externalID) {
    return [null, false, false, null];
  }

  if (disabledMerchants.includes(externalID || '')) {
    return [{ Disabled: true } as ReferralLinkIntegration, false, false, null];
  }

  React.useEffect(() => {
    if (!integrationID && (!externalID || !integrationType)) {
      return;
    }

    // Limit the time spent in the foreground looking up the merchant
    // integration to 3 seconds. This can then fallback to non-merchant
    // referral until the data is ready (if ever).
    const timeout = setTimeout(() => {
      if (loading) {
        setLoading(false);
        setBGLoading(true);
      }
    }, 3000);

    setLoading(true);

    getMerchantLink(integrationType, externalID, integrationID)
      .then((integration) => {
        setMerchantAttribution(integration);
        setIntegration(integration);

        if (loadRentalConfiguration) {
          return getPublicMerchantRentalConfiguration(integration.IntegrationID)
            .then((r) => setRentalConfig(r.Configuration))
            .catch(() => setRentalConfig(null));
        }
      })
      .finally(() => {
        setLoading(false);
        setBGLoading(false);
        clearTimeout(timeout);
      });

    return () => clearTimeout(timeout);
  }, [integrationType, externalID, integrationID]);

  return [integration, loading, bgLoading, rentalConfig];
};

const LandingPageRentalDetails = (props: {
  merchant: ReferralLinkIntegration;
  textColor?: string;
  secondaryColor?: string;
  externalID?: string;
  merchantBGLoading?: boolean;
}) => {
  return (
    <PageSection noBorder>
      <div style={{ color: props.textColor || 'white' }}>
        <h1 style={{ marginTop: 0 }}>Protect your rental from theft and damage.</h1>
        <p>
          <DisplayMerchantName merchant={props.merchant} merchantHandle={props.externalID || ''} />{' '}
          has partnered with Oyster to provide comprehensive damage and theft protection for your
          rental. Now, you can ride worry-free with protection from some of the most common rental
          risks. Get started below.
        </p>
        <CoveragePageSection title="Coverage" color={props.textColor}>
          <CoverageItem
            title="Accidental Damage"
            description="Damage caused by accidents, crashes, and collisions, while using the equipment in its intended manner, is covered."
            iconColor={props.secondaryColor}
          />
          <CoverageItem
            title="Theft"
            description="Stolen equipment, even when unattended and secured, is covered."
            iconColor={props.secondaryColor}
          />
          <CoverageItem
            title="Catastrophic Damage"
            description="Damage caused by catastrophes like fire, hurricane, tornado, and hail is covered."
            iconColor={props.secondaryColor}
          />
          <CoverageItem
            title="Accessories"
            description="Additional accessories, like bike locks, helmets, and flashlights are covered with your rental equipment."
            iconColor={props.secondaryColor}
          />
        </CoveragePageSection>
      </div>
    </PageSection>
  );
};

const LandingPageUnifiedWaiverDetails = (props: {
  merchant: ReferralLinkIntegration;
  textColor?: string;
  secondaryColor?: string;
}) => {
  return (
    <PageSection noBorder>
      <div style={{ color: props.textColor || 'white' }}>
        <h1 style={{ marginTop: 0 }}>Get started with your rental.</h1>
        <p>
          Before you start riding, complete the rental agreement for{' '}
          <DisplayMerchantName merchant={props.merchant} merchantHandle="" /> by filling out the
          form details, reading the agreement documents, and providing your signature
          electronically.
        </p>
        <CoveragePageSection title="Agreement Overview" color={props.textColor}>
          <CoverageItem
            title="Rental Responsibility"
            description="You agree to take responsibility for the rented equipment and return it in its original condition."
            iconColor={props.secondaryColor}
          />
          <CoverageItem
            title="Liability Release"
            description={`You agree to release ${props.merchant.MerchantName} from any liability resulting from this rental.`}
            iconColor={props.secondaryColor}
          />
          <CoverageItem
            title="Restrictions on Use"
            description="You agree to only use this equipment according to the specified terms."
            iconColor={props.secondaryColor}
          />
          <CoverageItem
            title="Damage and Theft Waiver"
            description="You have the option to waive responsibility for theft and damage for an additional fee (terms apply)."
            iconColor={props.secondaryColor}
          />
        </CoveragePageSection>
      </div>
    </PageSection>
  );
};

const InsuredItemContainer = styled.div`
  border-radius: 16px;
  padding: 16px;
  border: 2px dotted #eaeaea;
  transition: all 0.15s ease-in-out;
  display: flex;
  gap: 16px;
  flex: 1 0 0;
`;

const InsuredItemDetails = styled.div`
  flex: 1 0 0px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const InsuredItemTitle = styled.div`
  gap: 16px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
`;

const InsuredItemContent = styled.div`
  font-size: 0.9em;
  color: #666666;
`;

export const RentalDescription = (props: {
  itemName: string;
  serialNumber: string;
  accessories: string[];
  startDate: Date;
  endDate: Date;
}) => {
  const formattedRentalDate = () => {
    const f = new Intl.DateTimeFormat(undefined, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit'
    });

    return `${f.format(props.startDate)} - ${f.format(props.endDate)}`;
  };

  return (
    <InsuredItemContainer>
      <div>
        <IoBicycle style={{ fontSize: '2.5em', color: '#637D8C' }} />
      </div>
      <InsuredItemDetails>
        <InsuredItemTitle>
          <div>{props.itemName}</div>
        </InsuredItemTitle>
        <InsuredItemContent>
          <div>{props.serialNumber}</div>
          {props.accessories.length > 0 && <div>{props.accessories.join(', ')}</div>}
          <div>{formattedRentalDate()}</div>
        </InsuredItemContent>
      </InsuredItemDetails>
    </InsuredItemContainer>
  );
};

export const InsuredDescription = (props: { insured: Insured }) => (
  <InsuredItemContainer>
    <div>
      <IoPerson style={{ fontSize: '2.5em', color: '#637D8C' }} />
    </div>
    <InsuredItemDetails>
      <InsuredItemTitle>
        <div>
          {props.insured.FirstName} {props.insured.LastName}
        </div>
      </InsuredItemTitle>
      <InsuredItemContent>
        <div>{props.insured.Email}</div>
        {props.insured.AddressCity &&
          props.insured.AddressState &&
          props.insured.AddressZipCode && (
            <div>
              {props.insured.AddressCity.trim()}, {props.insured.AddressState.trim()}{' '}
              {props.insured.AddressZipCode.trim()}
            </div>
          )}
        {!props.insured.AddressCity && props.insured.Phone && <div>{props.insured.Phone}</div>}
      </InsuredItemContent>
    </InsuredItemDetails>
  </InsuredItemContainer>
);

const DisplayMerchantName = (props: {
  merchant: ReferralLinkIntegration | null | undefined;
  merchantHandle: string;
}) => {
  if (props.merchant) {
    return <b> {props.merchant.MerchantName} </b>;
  }

  // we remove non-alphanumeric and lower-case characters, so we
  // need to compensate in the width estimate. assume one additional
  // character for every 4 characters.
  const padding = ' '.repeat(Math.ceil(props.merchantHandle.length / 4));
  // insert the padding somewhere in the middle of the sizing text so that
  // they don't get automatically stripped from either end.
  const sizingText = props.merchantHandle[0] + padding + props.merchantHandle.substring(1);

  // The text in this component is solely for a size estimate, it is not displayed.
  return <TextSkeleton>{sizingText}</TextSkeleton>;
};

const RentalLandingIntro = (props: {
  merchant: ReferralLinkIntegration;
  children: (textColor: string, secondaryColor: string) => JSX.Element;
}) => {
  const brandColor = '';
  const textColor = '';
  const secondaryColor = '';

  return (
    <PageBackground
      merchantBrandColor={brandColor}
      style={{
        minHeight: '0%',
        background: brandColor || '#0f172a',
        position: 'relative'
      }}
    >
      <PageContainer footerTextColor={textColor} footerStyle={FooterStyle.none} width={800}>
        <LogoContainer merchantBrandColor={brandColor}>
          <PageSection noBorder>
            <a href="https://withoyster.com">
              <OysterLogoOld color={secondaryColor} light />
            </a>
          </PageSection>
        </LogoContainer>
        {props.children(textColor, secondaryColor)}
      </PageContainer>
    </PageBackground>
  );
};

export const RentalLandingPage = (props: {
  externalId?: string;
  bookingId?: string;
  integrationType: MerchantIntegrationType;
}) => {
  if (!props.externalId) {
    return null;
  }

  const [merchant, merchantFGLoading, merchantBGLoading, rentalConfig] = useMerchant(
    props.integrationType,
    undefined,
    props.externalId,
    true
  );

  const merchantLoading = merchantFGLoading || merchantBGLoading;

  // Resolve external ID into a merchant and merchant rental configuration
  // If no merchant or no rental configuration, throw error

  if (!merchantLoading && !rentalConfig) {
    throw new Error('');
  }

  return (
    <TransitionGroup>
      {merchantLoading && (
        <CSSTransition component={null} unmountOnExit timeout={500} classNames="fade">
          <TransitionContainer>
            <PageContainer footerStyle={FooterStyle.none}>
              <PageSection noBorder centered>
                <Spinner color="#333333" />
              </PageSection>
            </PageContainer>
          </TransitionContainer>
        </CSSTransition>
      )}
      {rentalConfig && merchant && !merchantLoading && (
        <CSSTransition component={null} unmountOnExit timeout={500} classNames="fade">
          <TransitionContainer>
            <>
              <RentalLandingIntro merchant={merchant}>
                {(textColor, secondaryColor) =>
                  rentalConfig?.UnifiedWaiverFlowEnabled ? (
                    <LandingPageUnifiedWaiverDetails
                      merchant={merchant}
                      textColor={textColor}
                      secondaryColor={secondaryColor}
                    />
                  ) : (
                    <LandingPageRentalDetails
                      merchant={merchant}
                      textColor={textColor}
                      secondaryColor={secondaryColor}
                    />
                  )
                }
              </RentalLandingIntro>
              <PageContainer width={800}>
                <PageSection>
                  <RentalApplication
                    integrationId={merchant.IntegrationID}
                    bookingId={props.bookingId}
                    rentalConfig={rentalConfig}
                  />
                </PageSection>
              </PageContainer>
            </>
          </TransitionContainer>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};
