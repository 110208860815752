import {
  AddressElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {
  completeRentalBooking,
  completeRentalWaiver,
  getRentalBooking,
  getRentalWaiver,
  getRentalWaiverPaymentSession
} from '@oysterjs/core/api/merchant';
import { Button, ButtonContainer } from '../../components/Button';
import { Checkbox } from '@oysterjs/ui/Form/checkbox';
import { ErrorDisplay } from '@oysterjs/ui/Form/text';
import { Loadable } from '@oysterjs/ui/Loadable';
import { Spinner } from '@oysterjs/ui/Spinner';
import { GetOysterEventNames } from '@oysterjs/core/analytics/googletags';
import { useAnalytics } from '@oysterjs/core/analytics/analytics';
import {
  BillingMode,
  DeepPartial,
  Insured,
  RentalBooking,
  RentalBookingState,
  RentalWaiver,
  ValidationError,
  WaiverState
} from '@oysterjs/types';
import loadStripe from '@oysterjs/core/stripe';
import { DetailsContainer } from './components';
import config from '@oysterjs/core/config';
import { useHistory } from 'react-router';
import {
  InsuredDescription,
  RenewalDescription,
  RentalDescription
} from './RentalBookingDetailsPage';
import { PageSection } from '../../components/Page';
import { ErrorCode, ErrorType, WrappedError } from '@oysterjs/core/errors';
import { FormContainer, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { AddressInputForm } from '@oysterjs/ui/Form/address';
import { IoArrowForward } from 'react-icons/io5';
import ReactSignatureCanvas from 'react-signature-canvas';

const SignatureComponent = (props: { onSignatureChange: (signatureBase64: string) => void }) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const canvasRef = React.useRef<ReactSignatureCanvas>(null);
  const [canvasWidth, setCanvasWidth] = React.useState(600);

  React.useEffect(() => {
    if (wrapperRef.current) {
      new ResizeObserver(() => {
        if (wrapperRef.current) {
          setCanvasWidth(wrapperRef.current.clientWidth);
        }
      }).observe(wrapperRef.current);
      setCanvasWidth(wrapperRef.current.clientWidth);
    }
  }, [wrapperRef.current]);

  React.useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current.getCanvas();

      canvas.addEventListener('mouseup', () =>
        props.onSignatureChange(canvasRef.current?.toDataURL('image/png', 0.5) || '')
      );
      canvas.addEventListener('touchend', () =>
        props.onSignatureChange(canvasRef.current?.toDataURL('image/png', 0.5) || '')
      );
    }
  }, [canvasRef.current]);

  const clearSignature = () => {
    canvasRef.current?.clear();
    props.onSignatureChange('');
  };

  return (
    <div ref={wrapperRef} style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
      <div style={{ position: 'relative' }}>
        <div
          style={{
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            background: '#0EA5E9',
            color: 'white',
            padding: '4px 8px',
            fontSize: '0.7em',
            display: 'inline'
          }}
        >
          Your signature here
        </div>
        <SignatureCanvas
          ref={canvasRef}
          canvasProps={{
            style: {
              display: 'block',
              height: '150px',
              width: `${canvasWidth}px`,
              marginTop: '2px',
              border: '1px solid #0EA5E9',
              borderRadius: '4px',
              borderTopLeftRadius: '0px'
            }
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            color: '#999999',
            fontSize: '0.8em',
            fontWeight: 500,
            cursor: 'pointer'
          }}
          onClick={clearSignature}
        >
          Clear
        </div>
      </div>
    </div>
  );
};

const calculateWaiverPricing = (waiver: RentalWaiver): number => {
  return waiver.Details.Premium.Total;
};

const CustomerConsent = (props: {
  waiver?: RentalWaiver;
  consented: boolean;
  includePremium?: boolean;
  onChangeConsent: (consented: boolean) => void;
}) => {
  const paymentLanguage =
    props.includePremium && props.waiver ? (
      <>
        I agree to pay Oyster Technologies{' '}
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: props.waiver.Details.PremiumRounding.Currency,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(calculateWaiverPricing(props.waiver))}{' '}
        to execute this agreement.
      </>
    ) : null;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '10px'
      }}
    >
      <Checkbox
        label="ElectronicCommunicationsConsent"
        checked={props.consented}
        onChange={() => props.onChangeConsent(!props.consented)}
      />
      <div
        style={{
          fontSize: '0.9em',
          color: '#999999',
          marginLeft: '10px'
        }}
      >
        I consent to give my signature electronically and agree to the terms of the rental, the
        terms of the{' '}
        <a
          href={`${config().serviceBaseUrl.getoyster}/rental/waiver/${props.waiver?.ID}/agreement`}
          target="_blank"
        >
          Loss Damage Waiver
        </a>
        , and{' '}
        <a href="https://www.withoyster.com/terms-conditions" target="_blank">
          Oyster's terms and conditions
        </a>
        . {paymentLanguage}
      </div>
    </div>
  );
};

const WaiverPaymentAndAddressCollection = (props: {
  booking: RentalBooking;
  waiver: RentalWaiver;
  getReturnUrl: () => string;
  setSignatureData: (signatureData: string) => void;
  continueLabel?: string;
  onBack?: ((e) => void) | null;
}) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();

  const [hasConsent, setHasConsent] = React.useState(false);
  const [signatureData, setSignatureData] = React.useState<string>('');

  React.useEffect(() => {
    props.setSignatureData(signatureData);
  }, [signatureData]);

  const stripe = useStripe();
  const elements = useElements();
  const analytics = useAnalytics();

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    if (!hasConsent) {
      setError('Please check the box and agree to the terms.');
      return;
    }

    if (!signatureData) {
      setError('Please sign in the box above.');
      return;
    }

    setLoading(true);
    analytics.event(GetOysterEventNames.WaiverPaymentSubmitted);

    const options = {
      elements,
      confirmParams: {
        return_url: props.getReturnUrl()
      }
    };
    const { error } = await stripe.confirmPayment(options);

    if (error && error.type !== 'validation_error') {
      setError(error.message || '');
    }

    setLoading(false);
  };

  return (
    <>
      <form
        style={{
          padding: '16px 0px 0px 0px',
          display: 'flex',
          gap: '10px',
          flexDirection: 'column'
        }}
      >
        <PaymentElement options={{ terms: { card: 'never' } }} />
        <AddressElement
          options={{
            mode: 'billing',
            allowedCountries: ['us'],
            autocomplete: { mode: 'automatic' },
            defaultValues: {
              name: `${props.booking.Details.Insured.FirstName} ${props.booking.Details.Insured.LastName}`
            }
          }}
        />
      </form>
      <SignatureComponent onSignatureChange={setSignatureData} />
      <CustomerConsent
        waiver={props.waiver}
        consented={hasConsent}
        onChangeConsent={setHasConsent}
        includePremium
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20px'
        }}
      >
        <Button
          primary
          disabled={loading || !stripe || !elements}
          loading={loading}
          onClick={handleSubmit}
        >
          {props.continueLabel || 'Sign and Pay'}
        </Button>
      </div>

      {(!stripe || !elements) && (
        <div style={{ padding: '16px 0px' }}>
          <Spinner color="#333333" />
        </div>
      )}
      {error && <ErrorDisplay style={{ paddingBottom: '16px' }}>{error}</ErrorDisplay>}
    </>
  );
};

const PaymentAndAddressInput: React.FunctionComponent<{
  getPaymentIntent: () => Promise<string>;
  booking: RentalBooking;
  waiver: RentalWaiver;
  getReturnUrl: () => string;
  setSignatureData: (signatureData: string) => void;
  continueLabel?: string;
  onBack?: ((e) => void) | null;
}> = (props) => (
  <Loadable request={props.getPaymentIntent()}>
    {(clientSecret) => (
      <Elements
        stripe={loadStripe()}
        options={{
          clientSecret,
          fonts: [
            {
              family: 'Inter',
              style: 'normal',
              weight: '400',
              display: 'swap',
              src: "url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2')",
              unicodeRange:
                'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF'
            },
            {
              family: 'Inter',
              style: 'normal',
              weight: '400',
              display: 'swap',
              src: "url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2')",
              unicodeRange:
                'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
            },
            {
              family: 'Inter',
              style: 'normal',
              weight: '500',
              display: 'swap',
              src: "url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2')",
              unicodeRange:
                'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF'
            },
            {
              family: 'Inter',
              style: 'normal',
              weight: '500',
              display: 'swap',
              src: "url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2')",
              unicodeRange:
                'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
            },
            {
              family: 'Inter',
              style: 'normal',
              weight: '600',
              display: 'swap',
              src: "url(https://fonts.gstatic.com/s/inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZFhiI2B.woff2) format('woff2')",
              unicodeRange:
                'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF'
            },
            {
              family: 'Inter',
              style: 'normal',
              weight: '600',
              display: 'swap',
              src: "url(https://fonts.gstatic.com/s/inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZ9hiA.woff2) format('woff2')",
              unicodeRange:
                'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
            },
            {
              family: 'Inter',
              style: 'normal',
              weight: '700',
              display: 'swap',
              src: "url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2')",
              unicodeRange:
                'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF'
            },
            {
              family: 'Inter',
              style: 'normal',
              weight: '700',
              display: 'swap',
              src: "url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2')",
              unicodeRange:
                'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
            }
          ],
          appearance: {
            theme: 'stripe',
            variables: {
              fontFamily: "'Inter', 'Helvetica Neue', 'Helvetica', Arial, sans-serif",
              borderRadius: '20px',
              fontSizeBase: '1em',
              fontWeightNormal: '400',
              colorPrimary: '#0EA5E9',
              colorBackground: '#ffffff',
              colorText: '#000000',
              colorDanger: '#d1344b',
              spacingGridRow: '16px',
              spacingGridColumn: '24px'
            },
            rules: {
              '.Label, .Input, .Error': {
                letterSpacing: '-0.2px'
              },
              '.Label': {
                color: '#666666',
                fontSize: '0.9em',
                fontWeight: '500'
              },
              '.Input': {
                padding: '8px 16px',
                border: '2px solid #e8e8e8',
                boxShadow: '0'
              },
              '.Input:focus': {
                boxShadow: '0',
                borderColor: 'var(--colorPrimary)'
              },
              '.Input--invalid': {
                boxShadow: '0',
                color: '#000000'
              },
              '.Error': {
                fontSize: '0.8em',
                marginTop: '5px'
              }
            }
          }
        }}
      >
        <WaiverPaymentAndAddressCollection
          booking={props.booking}
          waiver={props.waiver}
          getReturnUrl={props.getReturnUrl}
          setSignatureData={props.setSignatureData}
          continueLabel={props.continueLabel}
          onBack={props.onBack}
        />
      </Elements>
    )}
  </Loadable>
);

export const RentalCustomerForm = (props: {
  waiverId?: string;
  bookingId?: string;
  confirmationReturnUrl: string;
}) => {
  const history = useHistory();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [rental, setRental] = React.useState<{ Waiver?: RentalWaiver; Booking: RentalBooking }>();

  const [insured, setInsured] = React.useState<DeepPartial<Insured>>({});

  const [completeLoading, setCompleteLoading] = React.useState(false);
  const [validationError, setValidationError] = React.useState<ValidationError>();
  const [error, setError] = React.useState<string>();

  const [hasConsent, setHasConsent] = React.useState(false);
  const [signatureData, setSignatureData] = React.useState<string>('');

  // We're using a workaround here because the endpoint is burst called 4x
  const [stripeSecret, setStripeSecret] = React.useState<string>();

  const url = new URL(props.confirmationReturnUrl);

  React.useEffect(() => {
    // We set this in local storage so that the signature can be accessed
    // on the completion page. We can't just put this in the URL because
    // the data is likely to exceed the maximum allowed length of a URL (2048)
    window.localStorage.setItem(`signature_${props.waiverId || props.bookingId}`, signatureData);
  }, [signatureData]);

  React.useEffect(() => {
    // We set this in local storage so that the insured can be accessed
    // on the completion page in the same way that the signaure data is.
    window.localStorage.setItem(
      `insured_${props.waiverId || props.bookingId}`,
      JSON.stringify(insured)
    );
  }, [insured]);

  const fetchData = async () => {
    // Skip fetching if waiver ID is not available
    if (!props.waiverId && !props.bookingId) {
      return;
    }

    setLoading(true);

    try {
      if (props.waiverId) {
        // Get waiver and booking object
        const entry = await getRentalWaiver(props.waiverId);
        if (!entry.Waiver || !entry.Booking) {
          throw new Error('Invalid data state on booking and waiver');
        }

        // Check if waiver is already processed
        if (entry.Waiver.State !== WaiverState.pending) {
          history.push(url.pathname + url.search);
        }

        // Get payment session
        const paymentSession = await getRentalWaiverPaymentSession(props.waiverId);
        setRental({
          Waiver: entry.Waiver,
          Booking: entry.Booking
        });
        setStripeSecret(paymentSession);
      }

      if (props.bookingId) {
        // Get waiver and booking object
        const entry = await getRentalBooking(props.bookingId);
        if (!entry.Booking) {
          throw new Error('Invalid data state on booking and waiver');
        }

        // Check if booking is already processed confirmed
        if (entry.Booking.State === RentalBookingState.Confirmed) {
          history.push(url.pathname + url.search);
        }

        setRental({ Booking: entry.Booking });
      }
    } finally {
      setLoading(false);
    }
  };

  const onChangeAddress = (fields: [keyof Insured, string | undefined][]) => {
    setValidationError(undefined);
    setInsured((prev) => ({
      ...prev,
      ...Object.fromEntries(fields)
    }));
  };

  const completeWaiver = async () => {
    if (!hasConsent) {
      setError('Please check the box and agree to the terms.');
      return;
    }

    if (!signatureData) {
      setError('Please sign in the box above.');
      return;
    }

    try {
      setError(undefined);
      setCompleteLoading(true);

      // Process completion
      if (props.waiverId) {
        await completeRentalWaiver(props.waiverId, signatureData, insured);
      }

      if (props.bookingId) {
        await completeRentalBooking(props.bookingId, signatureData, insured);
      }

      history.push(url.pathname + url.search);
    } catch (e) {
      // Check for already activated error
      const err = WrappedError.asWrappedError(e);
      if (
        err.type() === ErrorType.preconditionFailed &&
        err.code() === ErrorCode.alreadySubmitted
      ) {
        history.push(url.pathname + url.search);
      } else if (err.type() === ErrorType.validationError) {
        setValidationError(err.getValidationError());
      } else {
        setError(err.message);
      }
    } finally {
      setCompleteLoading(false);
    }
  };

  React.useEffect(() => {
    setError(undefined);
  }, [hasConsent]);

  React.useEffect(() => {
    fetchData();
  }, [props.waiverId]);

  return (
    <>
      <h1>Your rental confirmation</h1>
      {loading && (
        <PageSection noBorder noPadding centered>
          <Spinner color="#333333" />
        </PageSection>
      )}
      {!loading && rental && (
        <>
          <p>
            Please review the details of your booking and the terms of your rental waiver. In order
            for this waiver to be in effect, please confirm below <b>before</b> you start your
            rental.
          </p>
          <DetailsContainer>
            <InsuredDescription insured={rental.Booking.Details.Insured} />
            {rental?.Booking.Details.AutoRenew && (
              <RenewalDescription
                waiver={rental.Waiver}
                booking={rental.Booking}
                showRenewalPeriod={true}
              />
            )}
            {rental.Booking.Details.Assets?.map((asset) => (
              <RentalDescription
                key={asset.ID}
                itemName={asset.Name}
                serialNumber={asset.Details.SerialNumber}
                accessories={rental.Booking.Details.Accessories.map((a) => a.Name)}
                startDate={new Date(rental.Booking.StartTime)}
                endDate={new Date(rental.Booking.EndTime)}
              />
            ))}
          </DetailsContainer>
          {rental.Waiver && rental.Waiver.Details.BillingMode !== BillingMode.Merchant && (
            <>
              <div>
                <h3 style={{ marginBottom: '5px' }}>Amount Due</h3>
                <div>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: rental.Waiver.Details.PremiumRounding.Currency,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(calculateWaiverPricing(rental.Waiver))}
                </div>
              </div>
              <div>
                <h3 style={{ marginBottom: '5px' }}>Payment Details</h3>
                <PaymentAndAddressInput
                  booking={rental.Booking}
                  waiver={rental.Waiver}
                  continueLabel="Sign and Pay"
                  getReturnUrl={() => url.toString()}
                  setSignatureData={setSignatureData}
                  getPaymentIntent={() => Promise.resolve(stripeSecret || '')}
                />
              </div>
            </>
          )}
          {(!rental.Waiver || rental.Waiver.Details.BillingMode === BillingMode.Merchant) && (
            <FormContainer onSubmit={(e) => e.preventDefault()}>
              <FormRowHeader title="Address" description="Enter your residential address" />
              <AddressInputForm
                disabled={completeLoading}
                showSecondLine
                singleSecondLine
                onChange={(address) => {
                  onChangeAddress([
                    ['AddressLine1', address.streetAddress],
                    ['AddressLine2', address.streetAddressLine2],
                    ['AddressCity', address.city],
                    ['AddressState', address.state],
                    ['AddressZipCode', address.zipCode]
                  ]);
                }}
                validationError={{
                  streetAddress:
                    validationError?.Field === 'AddressLine1' && validationError?.Message,
                  city: validationError?.Field === 'AddressCity' && validationError?.Message,
                  state: validationError?.Field === 'AddressState' && validationError?.Message,
                  zipCode: validationError?.Field === 'AddressZipCode' && validationError?.Message
                }}
              />
              <SignatureComponent onSignatureChange={setSignatureData} />
              <CustomerConsent
                waiver={rental.Waiver}
                consented={hasConsent}
                onChangeConsent={setHasConsent}
              />
              <ButtonContainer center>
                <Button
                  primary
                  icon={<IoArrowForward />}
                  onClick={completeWaiver}
                  loading={completeLoading}
                >
                  Agree and Continue
                </Button>
              </ButtonContainer>
            </FormContainer>
          )}
          {error && <ErrorDisplay>{error}</ErrorDisplay>}
        </>
      )}
    </>
  );
};
