import React from 'react';
import { IoArrowForward } from 'react-icons/io5';
import { completeRentalBooking, completeRentalWaiver } from '@oysterjs/core/api/merchant';
import { Button } from '../../components/Button';
import { ErrorDisplay } from '@oysterjs/ui/Form/text';
import { PageSection } from '../../components/Page';
import { Spinner } from '@oysterjs/ui/Spinner';
import { ErrorCode, ErrorType, WrappedError } from '@oysterjs/core/errors';
import { RentalBookingState, WaiverState } from '@oysterjs/types';

export const RentalCompletionPage = (props: {
  waiverId?: string;
  bookingId?: string;
  onClose?: () => void;
}) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string>();
  const [waiverActivated, setWaiverActivated] = React.useState<boolean>();

  const signatureData =
    window.localStorage.getItem(`signature_${props.waiverId || props.bookingId}`) || '';

  const insured = JSON.parse(
    window.localStorage.getItem(`insured_${props.waiverId || props.bookingId}`) || '{}'
  );

  const handleCompletion = async () => {
    setLoading(true);
    let activated = false;

    try {
      if (props.waiverId) {
        const waiver = await completeRentalWaiver(props.waiverId, signatureData, insured);
        activated = waiver?.State === WaiverState.active;
      }

      if (props.bookingId) {
        const booking = await completeRentalBooking(props.bookingId, signatureData, insured);
        activated = booking?.State === RentalBookingState.Confirmed;
      }

      // Process completion
      if (activated) {
        setWaiverActivated(true);
      } else {
        setWaiverActivated(false);
        setError('Waiver not activated');
      }
    } catch (e) {
      // Check for already activated error
      const err = WrappedError.asWrappedError(e);
      if (
        err.type() === ErrorType.preconditionFailed &&
        err.code() === ErrorCode.alreadySubmitted
      ) {
        setWaiverActivated(true);
      } else {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleCompletion();
  }, [props.waiverId, props.bookingId]);

  return (
    <>
      {loading && (
        <>
          <h1>Just a moment...</h1>
          <p>
            Thank you for submitting your information. Just one moment as we process your
            information...
          </p>
          <PageSection noBorder noPadding centered>
            <Spinner color="#333333" />
          </PageSection>
        </>
      )}
      {!loading && !error && waiverActivated && (
        <>
          <h1>You're all set!</h1>
          <p>
            You've signed and accepted the rental terms. A copy of the documents will be emailed to
            you. There's nothing left for you to do except start riding!
          </p>
          <img style={{ width: '100%' }} src="/images/celebrate.svg" alt="" />
          {props.onClose && (
            <PageSection noBorder centered>
              <Button icon={<IoArrowForward />} primary onClick={props.onClose}>
                Close
              </Button>
            </PageSection>
          )}
        </>
      )}
      {error && (
        <>
          <h1>Oops!</h1>
          <p>
            There was an error submitting your information. Please reach out to{' '}
            <a href="mailto:support@withoyster.com">support@withoyster.com</a> and we'll help
            resolve the issue.
          </p>
          <ErrorDisplay>{error}</ErrorDisplay>
        </>
      )}
    </>
  );
};
