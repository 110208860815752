import React from 'react';
import { getRentalWaiverAgreementInformation } from '@oysterjs/core/api/merchant';
import styled from 'styled-components';

const Heading = styled.h3``;
const Subheading = styled.h4``;

export const RentalAgreement = (props: { waiverId: string }) => {
  const [signedDate, setSignedDate] = React.useState<string>('[signed date]');
  const [customerName, setCustomerName] = React.useState<string>('[customer name]');
  const [merchantName, setMerchantName] = React.useState<string>('[merchant name]');
  const [bookingDate, setBookingDate] = React.useState<string>('[date]');
  const [rentalPeriod, setRentalPeriod] = React.useState<string>('[rental period dates]');

  const fetchData = async () => {
    const res = await getRentalWaiverAgreementInformation(props.waiverId);
    setSignedDate(new Date(res.SignedDate).toLocaleDateString('en-US'));
    setBookingDate(new Date(res.RentalBookingDate).toLocaleDateString('en-US'));
    setCustomerName(res.CustomerName);
    setMerchantName(res.MerchantName);
    setRentalPeriod(
      `${res.RentalBookingDurationInDays} ${res.RentalBookingDurationInDays > 1 ? 'days' : 'day'}`
    );
  };

  React.useEffect(() => {
    fetchData();
  }, [props.waiverId]);

  return (
    <>
      <h1>Rental Agreement</h1>
      <p>
        This Loss Damage Waiver (“LDW” or “Agreement”) is made on {signedDate}, between:{' '}
        <b>{customerName}</b> (“Customer”, “You”, “Your”) and <b>{merchantName}</b> (“Merchant”,
        “Our”, “We”) to waive liability in the event of damage or theft to the rented equipment
        pursuant to that certain rental contract between Customer and Merchant signed on{' '}
        {bookingDate} for {rentalPeriod} (the “Rental Contract”). Customer and Merchant may be
        referred to in this agreement individually as a “party” and collectively as “parties”.
        Oyster Insurance Agency LLC and Oyster Technologies Inc will be referred to as “Oyster”.
      </p>
      <p>
        This Loss Damage Waiver (LDW) is not insurance and is not mandatory. If you choose to
        purchase the LDW at the additional daily rate, for each full or partial day that the
        equipment is rented to you, and the equipment is operated in accordance with the Rental
        Contract and this Agreement, we will waive Your responsibility for the loss of or damage to
        the equipment subject to the Rental Contract except, if permitted by law, for, tire services
        unless related to an accident, or recovery of the equipment if stolen, (except in the state
        of Alaska), and except for your amount of “responsibility”, if any, specified on the Rental
        Contract. If you do not choose to purchase the LDW, then you are responsible for all loss or
        damage to the equipment. Loss and damage are described in the section below. YOU ACKNOWLEDGE
        YOU HAVE BEEN ADVISED THAT YOUR OWN INSURANCE MAY COVER LOSS OR DAMAGE TO THE EQUIPMENT. YOU
        ALSO ACKNOWLEDGE READING THE NOTICE ON LOSS OR DAMAGE SHOWN ON THE RENTAL CONTRACT, OR IN
        THESE TERMS, OR IN A SEPARATE NOTICE FORM, INCLUDING WITHOUT LIMITATION THE STATE SPECIFIC
        NOTICES SET FORTH WITH RESPECT TO THE STATE IN WHICH YOU RENTED THE EQUIPMENT AND EACH STATE
        WHERE YOU TAKE THE EQUIPMENT.
      </p>
      <p>
        If selected for the initial rental period then the LDW will extend to future periods
        continuous with the original period, if disclosed before the extension takes place, at the
        updated quoted price. If the rental extension is notified after the extension period begins
        then the extra charge may be at the daily calculated rate.
      </p>
      <Heading>Covered Damage</Heading>
      <>
        If the equipment is lost or damaged as a direct or indirect result of a violation of
        Prohibited Use (as defined below), you are responsible and you will pay the Merchant for all
        loss of or damage to the equipment regardless of cause, or who, or what caused it. If the
        law of a jurisdiction covering this rental requires conditions on LDW that are different
        than the terms of the Rental Contract, such as if your liability for ordinary negligence is
        limited by such law, that law prevails.
      </>
      <Heading>Making a Claim</Heading>
      <>
        The Merchant shall waiver your obligations of payment under the Rental Contract upon the
        occurrence of an unrecovered theft of the equipment, provided that ALL of the following
        conditions have been met:
        <ol type="1">
          <li>
            You report any such theft to law enforcement authorities within forty-eight (48) hours
            of knowledge of the theft;
          </li>
          <li>
            You report to Us within three (3) days of knowledge of any such theft; and (3) You
            provide proof of loss to Us, including such documents and information as we may require,
            within a reasonable period of time, usually within ten (10) days of the receipt of the
            police report. All reporting of any knowledge of theft or documentation and information
            related to Your proof of loss must be made either by contacting us by phone at
            201-430-5289, by email at support@withoyster.com, or online at
            https://www.withoyster.com.
          </li>
        </ol>
      </>
      <p>
        Such waiver shall not apply, however, if the equipment is misplaced or improperly locked
        (faulty lock-up can include locking through a wheel and not through the frame). In such an
        instance, you will be liable to pay the full replacement cost of the equipment, as set forth
        in the Rental Contract.
      </p>
      <Heading>Exclusions</Heading>
      <Subheading>Gross Negligence</Subheading>
      No damage or loss will be covered if the damage or loss is due to the gross negligence of the
      primary operator or other authorized operators.
      <Subheading>Prohibited Use</Subheading>
      <>
        You must not use or allow the equipment to be used in a manner constituting a Prohibited
        Use. You are responsible for the actions of any authorized operators. If a Prohibited Use
        causes, or contributes to, loss or damage to the equipment, you are liable to us for that
        loss or damage up to the full value of the equipment. Prohibited Uses of a equipment by you
        or an authorized operator are:
        <ul>
          <li>
            the equipment operator acted recklessly, with deliberate intent, or with willful
            disregard to cause loss or damage to the equipment;
          </li>
          <li>
            the equipment is damaged in the following manner: ○ operating the equipment with a flat
            tire
          </li>
          <li>
            operating the equipment while under the influence of any drug, substance or intoxicating
            liquor to the extent that the operator’s ability to control the equipment is impaired or
            where the operator’s blood level of any drug or alcohol is over the applicable legal
            limit including without limitation any federally controlled substance listed under the
            Controlled Substance Act, Title 21 of the United States Code (a "Controlled Substance"),
            or medications that affect equipment operation and/or constitute driving while impaired
            under applicable law. If a person refuses or fails to provide a breath, blood or other
            sample when lawfully required to do so by police or as required by law, the operator is
            deemed to be over the legal limit (for the avoidance of doubt this includes operating
            the equipment or allowing it to be operated in circumstances that constitute an
            offense);
          </li>
          <li>
            improperly locking or parking the equipment leading to the equipment’s theft or damage;
          </li>
          <li>
            fitting of objects to the interior or exterior of the equipment not authorized by the
            Merchant;
          </li>
          <li>the equipment is used for committing an illegal or unlawful act;</li>
          <li>the equipment is sub-rented, transferred or sold;</li>
          <li>
            the equipment is used to in a commercial context to carry passengers (e.g. as a rickshaw
            or equipment sharing arrangement) or property, for hire;
          </li>
          <li>
            the equipment is used for hauling any goods incorrectly or inappropriately loaded or for
            the haulage of which the equipment was not designed;
          </li>
          <li>
            the equipment is driven through a flooded road or otherwise through a body of water or
            natural disaster which could be avoided including fire, storm, or cyclone;
          </li>
          <li>the equipment is driven on terrain not specified for its operation;</li>
          <li>interfering with tracking of the equipment;</li>
          <li>allowing the equipment to be operated outside your authority;</li>
          <li>
            the equipment is used for or in conjunction with conduct that could be charged as a
            crime such as a felony or misdemeanor, including the transportation of a controlled
            substance or contraband, stolen goods, illegal devices, or persons protected by
            prohibitions against human trafficking;
          </li>
          <li>operating recklessly or while overloaded;</li>
          <li>operating the equipment, or allowing it to be operated, illegally.</li>
        </ul>
      </>
      <Heading>Responsibilities</Heading>
      <>
        You must comply with mandated helmet and child restraint laws. Never leave a child
        unattended in the equipment. You must promptly report to the Merchant any damage to or loss
        of the equipment when it occurs or when you learn of it and provide us with a written
        accident/incident report. Where required by law, you must report an accident to law
        enforcement. Theft claims must be accompanied by a police report or coverage may not apply.
      </>
      <Heading>Operators</Heading>
      <>
        The equipment may only be ridden by you and any other additional operators(s) named in the
        Rental Contract. Any person operating the equipment must be 12 years of age or over or the
        minimum legal operating age where applicable by law. The primary operator or customer must
        hold and present when the equipment is picked up, a current identification card and
        satisfies any other conditions on the Rental Contract or in any Additional Terms. You are
        responsible for ensuring that all additional operators(s) comply with the terms of the
        Rental Contract and this Agreement and you assume responsibility for any and all acts of
        each additional operator(s) as if they were your acts or omissions.
      </>
      <Heading>Security</Heading>
      <>
        You are responsible for securing the equipment including accessories and should minimize
        risk of theft or vandalism by parking and locking in a safe place. If theft or damage occurs
        due to improper parking or locking then you will be responsible for the loss. You must also
        comply with our return instructions.
      </>
      <Heading>Repairs</Heading>
      <>
        You understand that you are not authorized to repair or have the equipment repaired without
        our express prior written consent. If you repair or have the equipment repaired without our
        consent, you will pay the estimated cost to restore the equipment to the condition it was in
        prior to your rental.
      </>
      <Heading>Personal Possessions</Heading>
      <>
        You are responsible for loss or damage to personal possessions with the equipment even when
        the loss or damage is not your fault.
      </>
      <Heading>Fines, Expenses, Costs, and Administrative Fees</Heading>
      <>
        You agree to indemnify and hold Oyster, the Merchant, and any agent we authorize harmless
        for any tickets, citations, fines, penalties, interest and administrative fees. Please refer
        to the Rental Contract for any language around costs and fees associated with the rental.
      </>
      <Heading>Indemnification and Waiver</Heading>
      <>
        You shall defend, indemnify, and hold Oyster , Oyster’s parent and affiliated companies
        harmless from all losses, liabilities, damages, injuries, claims, demands, awards, costs,
        attorney fees, and other expenses incurred by Oyster in any manner from this rental
        transaction or from the use of the equipment by you or any person, including claims of, or
        liabilities to, third parties. You may present a claim to your insurance carrier for such
        events or losses; but in any event, you shall have final responsibility to us for all such
        losses. YOU WAIVE ANY CLAIM AGAINST OYSTER FOR INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES
        IN CONNECTION WITH THE RENTAL. You agree that any claim by you, including one that alleges
        unfair, deceptive or unconscionable conduct, your sole right and remedy is against the
        Merchant, its parent or any of its affiliated companies.
      </>
      <Heading>Error in Rental Charges</Heading>
      <>
        The charges shown on the return record for the LDW are not final and are subject to review.
        You’ll pay any undercharges and you’ll receive a refund for any overcharges we discover on
        review for the LDW.
      </>
      <Heading>Arbitration & Dispute Resolution</Heading>
      <>Please see your Rental Contract for any arbitration or dispute resolution proceedings.</>
      <Heading>Use of Tracking Devices</Heading>
      <>
        We may use GPS, bluetooth, or other tracking devices to track or locate equipments which may
        be late for their scheduled return, reported stolen, suspected of being lost, stolen, or
        abandoned or as may be required or requested by law enforcement, or to identify equipments
        which have been damaged and may require roadside assistance, when we have a good faith
        belief that there is an emergency that poses a threat to your safety or the safety of
        another person, or as necessary to defend, protect or enforce our rights in connection with
        the use of our products and/or services. You should have no expectation of privacy or
        confidentiality as to the places where the equipment is driven while rented to you.{' '}
        <p>
          For a copy of Oyster’s Privacy Notice, please go to{' '}
          <a href="https://www.withoyster.com/privacy-policy" target="_blank">
            Oyster Privacy Policy
          </a>{' '}
          page.
        </p>
        <p>
          Connecticut: To determine the annualized charge for Loss Damage Waiver (LDW or PDW),
          multiply the daily rate by 365 days. Loss Damage Waiver covers loss or damage due to
          theft, collision, vandalism, or any other cause. Whether or not you accept Loss Damage
          Waiver, or if Loss Damage Waiver is not permitted, you are responsible for the loss or
          damage to the equipment that is caused by you or by an authorized additional operator
          through intentional or willful and wanton misconduct; driving while intoxicated or using
          drugs; participation in any organized or racing competition; transporting persons or
          property for hire; commission of a felony or an act that could be a felony; failure to
          complete an accident or theft report; use or operation by an unauthorized operator;
          violation of the terms of the Agreement; use to push or tow something; or obtaining the
          equipment through fraud or misrepresentation. You should examine your insurance policies
          or credit card, including deductible and limit of coverage, because it may cover loss or
          damage and personal injury incurred while you are using or operating a rental equipment.
          If your responsibility for any loss or damage is covered by your own insurance, you will
          authorize the Merchant or its affiliates to deal with the carrier. The Merchant will
          refund any sum collected above the amount of the cost of repair.
        </p>{' '}
        <p>
          California, Iowa, Indiana, Louisiana, Hawaii, Massachusetts, Minnesota, New York, Nevada,
          Rhode Island and Texas, Consumer Protection: You should check your personal insurance
          policy to review if the purchase of LDW would be duplicative of coverage afforded under
          your personal insurance policy.
        </p>{' '}
        <p>Wisconsin:</p> <p>NOTICE ABOUT LIABILITY FOR DAMAGE TO THE RENTAL EQUIPMENT</p> The State
        of Wisconsin requires us to provide the following information about your liability for
        damage to rental equipment and the purchase of a damage waiver. On rentals in Wisconsin, all
        provisions of the Agreement are applicable, except to the extent of a conflict between the
        Wisconsin specific terms and the other terms of the Agreement, in which case the Wisconsin
        specific terms shall govern. <p>NOTICE OF RIGHT TO INSPECT DAMAGE</p> If the equipment is
        damaged, we may not collect any amount for the damage unless you, or an authorized operator
        against whom we claim liability, have been promptly notified of your right to inspect the
        unrepaired equipment within two working days after we were notified of the damage. If you
        request, we must also give you a copy of any estimate we have obtained from a repair shop,
        including ourselves, regarding any damage claim. Within 2 working days after receiving the
        estimates, you may request a second estimate from a competing repair shop and we must give
        you a copy of the second estimate. <p>COMPLAINTS</p> If you have any complaints about our
        attempt to hold you liable for damage or would like a copy of the state law that fully sets
        for your rights and obligations, contact:{' '}
        <p>
          Wisconsin Consumer Protection Bureau, P.O. Box 8911, Madison, WI 53708-8911 608-224-4960
          (Madison area) or toll-free 1-800-422-7128
        </p>
      </>
    </>
  );
};
