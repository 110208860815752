import { Cookies } from 'react-cookie';
import { ReferralLinkIntegration } from '@oysterjs/types';

const ATTRIBUTION_COOKIE = 'oyster_attribution';

export enum CookieParameters {
  AttributionType = 'type',
  MerchantIntegrationId = 'merchant_integration_id',
  UtmSource = 'utm_source',
  UtmCampaign = 'utm_campaign',
  UtmMedium = 'utm_medium',
  UtmTerm = 'utm_term',
  GoogleId = 'gclid',
  FacebookId = 'fbclid',
  PageHistory = '_oh'
}

enum AttributionCookieType {
  Merchant = 'merchant',
  Marketing = 'marketing',
  Direct = 'direct'
}

export const setMerchantAttribution = (integration: ReferralLinkIntegration) => {
  const cookies = new Cookies();
  const params = new URLSearchParams({
    [CookieParameters.AttributionType]: AttributionCookieType.Merchant,
    [CookieParameters.MerchantIntegrationId]: integration.IntegrationID
  });
  cookies.set(ATTRIBUTION_COOKIE, params.toString(), { path: '/' });
};
