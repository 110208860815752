import { init as initApm } from '@oysterjs/core/apm';
initApm();

import '../dashboard/style.scss';

import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Redirect, Route as BrowserRoute } from 'react-router-dom';

import * as Sentry from '@sentry/react';
const Route = Sentry.withSentryRouting(BrowserRoute);

import { MerchantIntegrationType } from '@oysterjs/types';
import config from '@oysterjs/core/config';

import { RentalLandingPage } from './pages/landing';
import { PageContainer, PageSection } from '@oysterjs/ui/Page';
import { useGoogleTags } from '@oysterjs/core/analytics/googletags';
import { HubspotChatContextProvider } from '@oysterjs/core/hubspot';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';
import { RentalCustomerForm } from '@oysterjs/partners/pages/rental/RentalCustomerForm';
import { RentalCompletionPage } from '@oysterjs/partners/pages/rental/RentalCompletionPage';
import { RentalAgreement } from '@oysterjs/partners/pages/rental/RentalAgreement';
import { OysterLogoOld } from '@oysterjs/ui/Logo';

const NotFound = () => (
  <PageContainer>
    <PageSection>
      <h1>404 Page Not Found</h1>
      <p>
        The page you're looking for could not be found. You can visit{' '}
        <a href="https://www.withoyster.com">our homepage here.</a>
      </p>
      <img
        height={250}
        style={{ display: 'block', margin: 'auto', padding: '40px 0px 0px 0px' }}
        src="https://dashboard.withoyster.com/images/no_policies.svg"
      />
    </PageSection>
  </PageContainer>
);

const App = () => {
  if (config().environment === 'production') {
    useGoogleTags();
  }

  return (
    <HubspotChatContextProvider>
      <Switch>
        <Route exact path="/m/:externalID/rental/:bookingId?">
          {(props) => (
            <ErrorBoundary>
              <RentalLandingPage
                externalId={props.match?.params.externalID}
                bookingId={props.match?.params.bookingId}
                integrationType={MerchantIntegrationType.referral_link}
              />
            </ErrorBoundary>
          )}
        </Route>
        <Route exact path="/q/:externalID/rental/:bookingId?">
          {(props) => (
            <ErrorBoundary>
              <RentalLandingPage
                externalId={props.match?.params.externalID}
                bookingId={props.match?.params.bookingId}
                integrationType={MerchantIntegrationType.qr_code}
              />
            </ErrorBoundary>
          )}
        </Route>
        <Route
          path="/rental/waiver/:id/confirm"
          render={(props) => (
            <PageContainer width={700}>
              <PageSection centered>
                <OysterLogoOld scale={1.5} inline />
              </PageSection>
              <PageSection noBorder>
                <ErrorBoundary forceMobile>
                  <RentalCustomerForm
                    waiverId={props.match.params.id}
                    confirmationReturnUrl={
                      window.location.origin +
                      `/rental/waiver/${props.match.params.id}/complete` +
                      window.location.search
                    }
                  />
                </ErrorBoundary>
              </PageSection>
            </PageContainer>
          )}
        />
        <Route
          path="/rental/waiver/:id/complete"
          render={(props) => (
            <PageContainer width={700}>
              <PageSection centered>
                <OysterLogoOld scale={1.5} inline />
              </PageSection>
              <PageSection noBorder>
                <ErrorBoundary forceMobile>
                  <RentalCompletionPage
                    waiverId={props.match.params.id}
                    onClose={
                      new URLSearchParams(window.location.search).get('from') === 'partner'
                        ? () => window.close()
                        : undefined
                    }
                  />
                </ErrorBoundary>
              </PageSection>
            </PageContainer>
          )}
        />
        <Route
          path="/rental/booking/:id/confirm"
          render={(props) => (
            <PageContainer width={700}>
              <PageSection centered>
                <OysterLogoOld scale={1.5} inline />
              </PageSection>
              <PageSection noBorder>
                <ErrorBoundary forceMobile>
                  <RentalCustomerForm
                    bookingId={props.match.params.id}
                    confirmationReturnUrl={
                      window.location.origin +
                      `/rental/booking/${props.match.params.id}/complete` +
                      window.location.search
                    }
                  />
                </ErrorBoundary>
              </PageSection>
            </PageContainer>
          )}
        />
        <Route
          path="/rental/booking/:id/complete"
          render={(props) => (
            <PageContainer width={700}>
              <PageSection centered>
                <OysterLogoOld scale={1.5} inline />
              </PageSection>
              <PageSection noBorder>
                <ErrorBoundary forceMobile>
                  <RentalCompletionPage
                    bookingId={props.match.params.id}
                    onClose={
                      new URLSearchParams(window.location.search).get('from') === 'partner'
                        ? () => window.close()
                        : undefined
                    }
                  />
                </ErrorBoundary>
              </PageSection>
            </PageContainer>
          )}
        />
        <Route
          path="/rental/waiver/:id/agreement"
          render={(props) => (
            <PageContainer width={700}>
              <PageSection centered>
                <OysterLogoOld scale={1.5} inline />
              </PageSection>
              <PageSection noBorder>
                <ErrorBoundary forceMobile>
                  <RentalAgreement waiverId={props.match.params.id} />
                </ErrorBoundary>
              </PageSection>
            </PageContainer>
          )}
        />
        <Route exact path="/404">
          <NotFound />
        </Route>
        <Redirect to="/" />
      </Switch>
    </HubspotChatContextProvider>
  );
};

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
